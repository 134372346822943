@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/global/sparta-style-utility/3.2.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;



$font-stack: Arial, Helvetica, sans-serif;
$primary-color: #333;

[data-sparta-container] .ah-widget-loader-module {

}
